import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title1, Body } from '../../../../components/Typography';
import OfferDetailsContainer from '../../OfferDetails/OfferDetailsContainer';
import appConfig from '../../../../../app-config';
import LoyaltyCardContainer from '../../../Loyalty/LoyaltyCardContainer';

const OffersListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  max-width: 904px;

  & > div:nth-child(even) {
    justify-self: end;
  }

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 264px auto;
  margin-bottom: 50px;
  margin-top: ${(props) => (!props.isLoyalty ? '150px' : '50px')};

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  @media (max-width: 786px) {
    margin-left: 20px;
    margin-right: 20px;
    justify-items: center;
  }

  @media (max-width: 360px) {
    margin-top: 100px;
  }
`;

const Title1Wrapper = styled(Title1)`
  justify-self: left;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: 200px;
    padding-top: 2px;
    border-bottom: solid
      ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const BodyWrapper = styled(Body)`
  font-style: italic;
  text-align: center;
`;

const OffersList = ({
  offers,
  requestingGetMenu,
  editProductConfiguration,
}) => {
  const [filteredOffers, setFilteredOffers] = useState([]);
  const updateFilteredOffers = useCallback(() => {
    const newFilteredOffers = [];
    offers.forEach((offer) => {
      offer.is_visible && newFilteredOffers.push(offer);
    });
    setFilteredOffers(newFilteredOffers);
  }, [offers]);
  useEffect(() => {
    updateFilteredOffers();
  }, [updateFilteredOffers]);

  return (
    <div style={{ marginTop: '150px' }}>
      {appConfig.isLoyaltyEnabled && (
        <Wrapper isLoyalty={appConfig.isLoyaltyEnabled}>
          <Title1Wrapper id="OfferList-AvailableOffers">
            Rewards Progress
          </Title1Wrapper>
          <OffersListWrapper>
            <LoyaltyCardContainer layoutStyle="offer" />
          </OffersListWrapper>
        </Wrapper>
      )}
      <Wrapper isLoyalty={appConfig.isLoyaltyEnabled}>
        <Title1Wrapper id="OfferList-AvailableOffers">
          Available Offers
        </Title1Wrapper>
        {!filteredOffers.length ? (
          <BodyWrapper>There are no offers available at the moment</BodyWrapper>
        ) : (
          <OffersListWrapper>
            {filteredOffers.map((offer, index) => (
              <OfferDetailsContainer
                key={index}
                offer={offer}
                requestingGetMenu={requestingGetMenu}
                editProductConfiguration={editProductConfiguration}
                layoutStyle="offer"
              />
            ))}
          </OffersListWrapper>
        )}
      </Wrapper>
    </div>
  );
};

export default OffersList;
