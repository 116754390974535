import React, {useState} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Headline from '../../../../components/Typography/Headline';
import Body from '../../../../components/Typography/Body';
import {FlatButton} from '../../../../components/Buttons';

const Transition = props => <Slide direction="down" {...props} />;

const styles = () => ({
  root: {
    width: '400px !important',
    maxWidth: '400px',
    margin: 'auto',
    margin: 0,
  },
  paper: {
    margin: 0,
    width: '400px',
  },
});

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
  grid-row-gap: 10px;
  display: grid;
`;

const Icon = styled.i`
  justify-self: center;
  font-size: 22px;
`;

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
`;

const NotAvailableModal = ({open, onClose, classes, type}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      classes={classes.root}
      PaperProps={{classes: {root: classes.paper}}}
      aria-labelledby="not-available"
      scroll="body"
    >
      <Wrapper>
        <Icon className="far fa-map-marker-exclamation"></Icon>
        <Headline id="Offers-NotAvailableHeadline">{type} not available</Headline>
        <Body id="Offers-NotAvailableMessage">
          We are sorry! This {type.toLowerCase()} is not available at this
          location. Please select a different location.
        </Body>
      </Wrapper>
      <ButtonRow>
        <FlatButton id="Offers-CancelButton" type="button" onClick={onClose}>
          Cancel
        </FlatButton>
        <FlatButton id="Offers-GoToMenuButton">
          <Link to="/menu" style={{textDecoration: 'none', color: 'inherit'}}>
            Go to Menu
          </Link>
        </FlatButton>
      </ButtonRow>
    </Dialog>
  );
};

export default withStyles(styles)(NotAvailableModal);
