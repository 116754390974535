import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Body, Headline } from '../../../../components/Typography';
import { withStyles } from '@material-ui/core/styles';
import OfferPoints from './OfferPoints';
import ProductCustomizerContainer from '../../../ProductCustomizer/ProductCustomizerContainer';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = (props) => <Slide direction="down" {...props} />;

const styles = (theme) => ({
  root: {
    borderRadius: '10px',
  },
  paper: {
    width: '768px',
    maxWidth: '768px',
  },
});
const MainDiv = styled.div`
  margin: 0;
  padding-bottom: ${(props) => (props.noProducts ? '0' : 'inherit')};
  padding: 20px 20px 20px 20px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;

const ButtonRow = styled.div`
  width: 100%;
  min-height: 36px;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  background: white;
  /* border: 1px solid black; */
  @media (max-width: 768px) {
    vertical-align: center;
    height: 57px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0px;
    box-shadow: none;
  }
`;
const OfferButton = styled.button`
  cursor: pointer;
  width: 343px;
  height: 56px;
  border-radius: 8px;
  background: ${(props) => props.theme.primaryButtonBackgroundColor};
  color: ${(props) => props.theme.primaryButtonFontColor};
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.primaryButtonHoverColor};
    color: ${(props) => props.theme.primaryButtonTextHoverColor};
    transition: all 0.25s ease;
  }
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
  }
`;
const ImageDiv = styled.div`
  position: relative;
  text-align: center;
  height: 300px;
`;

const DescriptionDiv = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
`;

const OfferSubtitle = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.38;
  letter-spacing: 0.03px;
  color: #717171;
`;

const OfferDescription = styled(Body)`
  line-height: 1.38;
  letter-spacing: 0.03px;
  color: #717171;
`;

const OfferImage = styled.img`
  text-align: center;
  height: 300px;
  object-fit: cover;
`;

const OfferTitle = styled.p`
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #231f20;
  margin-top: 16px;
  margin-bottom: 10px;
`;

const EligibleProductTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #231f20;
`;

const BorderDiv = styled.hr`
  background: #717171;
  height: 2px;
  width: 67px;
  margin-bottom: 15px;
`;

const ProductsDiv = styled.div`
  margin-bottom: 24px;
`;

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 20px;
  grid-gap: 16px;
  align-items: center;
  margin: 16px 0;
`;

const ProductName = styled(Headline)`
  letter-spacing: 0.03;
  cursor: ${(props) => (props.isAvailable ? 'pointer' : 'default')};
  color: ${(props) =>
    props.isAvailable ? '#231f20' : props.theme.unavailableProductColor};
`;

const ProductImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  filter: ${(props) => (props.isAvailable ? null : 'grayscale(0.9)')};
`;

const CheckDiv = styled.div``;

const OfferPointsWrapper = styled.div`
  right: 2px;
  bottom: 2px;
  position: absolute;
`;

const CloseDiv = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CloseWrapper = styled.div``;

const OfferDetailModal = ({
  classes,
  offer,
  onClose,
  modalState,
  activateOffer,
  deactivateOffer,
  allProducts,
  requestingGetMenu,
  editProductConfiguration,
  getSingleProduct,
  cartProducts,
  currentPointsBalance,
}) => {
  const [eligibleProducts, setEligibleProducts] = useState();
  const getEligibleProducts = useCallback(() => {
    let products = [];
    if (
      offer.offer_type === 'free-item' ||
      offer.offer_type === 'birthday-free-item'
    ) {
      products = offer.eligible_products;
    }
    if (
      offer.offer_type === 'discounted-item' ||
      offer.offer_type === 'birthday-discounted-item'
    ) {
      products = offer.discounted_products;
    }
    setEligibleProducts(products);
  }, [offer]);
  useEffect(() => {
    getEligibleProducts();
  }, [getEligibleProducts]);
  const handleOnClick = (offer) => {
    if (offer.activated) deactivateOffer(offer, onClose);
    if (!offer.activated) activateOffer(offer, onClose);
  };
  return (
    <Dialog
      open={modalState}
      onClose={onClose}
      // TransitionComponent={Transition}
      className={classes.root}
      PaperProps={{ classes: { root: classes.paper } }}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      aria-labelledby="show-offer-modal"
      // scroll="body"
    >
      {/* {!requestingGetMenu && (
      )} */}
      <MainDiv noProducts={!eligibleProducts?.length}>
        <div>
          <ImageDiv>
            <CloseWrapper>
              <CloseDiv
                id="OfferDetails-CloseButton"
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}
              >
                <i className="fal fa-times" />
              </CloseDiv>
            </CloseWrapper>
            {offer.offer_type === 'birthday-free-item' ? null : (
              <OfferPointsWrapper>
                <OfferPoints
                  id="OfferDetails-OfferPoints"
                  offer={offer}
                  currentPointsBalance={currentPointsBalance}
                  modalColor
                  offerDetailModal
                />
              </OfferPointsWrapper>
            )}
            <OfferImage src={offer.image_url} />
          </ImageDiv>

          <DescriptionDiv>
            <OfferTitle id="OfferDetails-Title">{offer.title}</OfferTitle>
            <OfferSubtitle id="OfferDetails-subtitle">
              {offer.subtitle}
            </OfferSubtitle>
            <OfferDescription id="OfferDetails-Description">
              {offer.description}
            </OfferDescription>
          </DescriptionDiv>
          {eligibleProducts?.length > 0 && (
            <ProductsDiv>
              <EligibleProductTitle>Eligible Products</EligibleProductTitle>
              <BorderDiv />
              {eligibleProducts.map((modalProduct, index) => (
                <div key={index}>
                  <ProductRow>
                    <ProductImage
                      src={modalProduct.image_url}
                      isAvailable={modalProduct.is_product_available}
                    />
                    <ProductName
                      id="OfferDetails-ProductName"
                      onClick={() =>
                        modalProduct.is_product_available &&
                        getSingleProduct(modalProduct, offer)
                      }
                      isAvailable={modalProduct.is_product_available}
                    >
                      {modalProduct.name}
                    </ProductName>
                    <CheckDiv id="OfferDetails-CheckDiv">
                      {cartProducts.some(
                        (cartProd) =>
                          cartProd.flatProduct.product_id ===
                          modalProduct.product_id
                      ) ? (
                        <i className="far fa-check"></i>
                      ) : null}
                    </CheckDiv>
                  </ProductRow>
                  <hr />
                </div>
              ))}
            </ProductsDiv>
          )}
        </div>
        {offer.auto_redeem ? null : (
          <ButtonRow noProducts={!eligibleProducts?.length}>
            <OfferButton
              id="OfferDetails-OfferButton"
              onClick={() => handleOnClick(offer)}
            >
              {offer.activated ? 'Deactivate Offer' : 'Activate Offer'}
            </OfferButton>
          </ButtonRow>
        )}
      </MainDiv>
    </Dialog>
  );
};

export default withStyles(styles)(OfferDetailModal);
