import React from 'react';
import {connect} from 'react-redux';
import Page404 from './404';
import OffersContainer from '../modules/Offers/OffersContainer';
import appConfig from '../../app-config';

const Offers = ({isAuthenticated}) => {
  if (isAuthenticated && appConfig.isOffersEnabled) {
    return <OffersContainer />;
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(Offers);
