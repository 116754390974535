import React from 'react';
import styled from 'styled-components';

const PointsDiv = styled.div`
  position: ${props => (props.offerDetailModal ? '' : 'absolute')};
  right: 12px;
  bottom: 13px;
  min-width: 90px;
  height: 32px;
  border-radius: 16.5px;

  background-color: ${props => props.theme.primaryButtonBackgroundColor};
  border: none;
  text-align: center;
`;

const PointsText = styled.div`
  color: ${props => props.theme.primaryButtonFontColor};
  vertical-align: middle;
  line-height: 32px;
  font-family: ${props => props.theme.primaryFontFontFamily};
  padding: 0 10px;
`;

const OfferPoints = ({
  offer,
  disabled,
  modalColor,
  currentPointsBalance,
  offerDetailModal = false,
}) => {
  const renderPoints = offer => {
    let offerRedemptionMethod = offer.auto_redeem ? 'Auto' : 'Manual';

    switch (offer.offer_type) {
      case 'discounted-item':
        return 'Free • ' + offerRedemptionMethod;
      case 'free-item':
        return (
          offer.offer_details.points_cost + ' Points • ' + offerRedemptionMethod
        );
      case 'birthday-free-item':
        return 'Free • ' + offerRedemptionMethod;
      case 'birthday-discounted-item':
        return 'Free • ' + offerRedemptionMethod;
      case 'carryover-loyalty':
        return (
          offer.offer_details.points_cost + ' Points • ' + offerRedemptionMethod
        );
      case 'first-time':
        return 'Free • ' + offerRedemptionMethod;
      default:
        return null;
    }
  };

  return (
    <PointsDiv
      disabled={disabled}
      modalColor={modalColor}
      offerDetailModal={offerDetailModal}
    >
      <PointsText id="OfferPoints-PointsText">
        {offer.activated ? <i className="fal fa-check" /> : renderPoints(offer)}
      </PointsText>
    </PointsDiv>
  );
};

export default OfferPoints;
