import React from 'react';
import {connect} from 'react-redux';
import InternalLoyaltyCard from './InternalLoyaltyCard/components/InternalLoyaltyCard';
import GiveXLoyaltyCardContainer from './GiveXLoyaltyCard/GiveXLoyaltyCardContainer';
import appConfig from '../../../app-config';
import InternalLoyaltyCardContainer from './InternalLoyaltyCard/InternalLoyaltyCardContainer';

const LoyaltyCardContainer = ({
  loyalty,
  requestingGetLoyalty,
  ...props
}) => {
  const {credits_available, loyalty_details} = loyalty || {};

  if (appConfig.loyaltyType === 'givex')
    return <GiveXLoyaltyCardContainer loyalty={loyalty} />;

  return (
    <InternalLoyaltyCardContainer
      {...props}
      // credits_available={credits_available}
      // nextOfferProgress={loyalty_details.details.next_offer.progress}
      // nextOfferTitle={loyalty_details.details.next_offer.data.title}
    />
  );
};

const mapStateToProps = state => ({
  loyalty: state.loyaltyReducer.loyalty,
  requestingGetLoyalty: state.loyaltyReducer.requestingGetLoyalty,
});

export default connect(mapStateToProps)(LoyaltyCardContainer);
