import React, {useState, useEffect} from 'react';
import OfferPoints from './OfferPoints';
import styled from 'styled-components';
import {Subhead, Caption1} from '../../../../components/Typography';
import OfferDetailModal from './OfferDetailModal';
import NotAvailableModal from './NotAvailableModal';

const GenericCard = styled.div`
  margin-top: ${props => (props.isFromReviewPage ? '10px' : 'inherit')};
  margin-bottom: ${props => (props.isFromReviewPage ? '22px' : 'inherit')};
  border-radius: 13px;
  background-color: white;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  cursor: pointer;
  width: 100%;
  min-width: ${props => (props.isFromReviewPage ? '300px' : '100%')};
  max-width: ${props => (props.isFromReviewPage ? '300px' : '440px')};
  margin-left:  ${props => (props.isFromReviewPage ? '10px' : '0')};

  :hover {
    box-shadow: ${props =>
      props.isFromReviewPage ? '' : '0 2px 7px 0 rgba(66,59,112,0.24)'};
    transition: all 0.25s ease;
  }

  @media (max-width: 500px) {
    max-width: ${props => (props.isFromReviewPage ? '300px' : '375px')};
  }
`;

const GenericCardImage = styled.img`
  height: 165px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;

  @media (max-width: 400px) {
    height: 185px;
  }
`;

const CardText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: white;
  padding: 10px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-top: 1px solid #f5f4f9;
  align-items: center;
`;

const DescriptionText = styled(Caption1)`
  font-weight: 300;
  color: #000;
`;
const OfferCardStyle = styled(GenericCard)`
  max-width: 440px;
  // height: 260px;
`;

const OfferCardImage = styled(GenericCardImage)`
  height: 200px;
`;

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
`;

const LockIcon = styled.i`
  position: absolute;
  left: 20px;
  bottom: 16px;
  color: white;
  font-size: 24px;
`;

const OfferCard = ({
  offer,
  activateOffer,
  deactivateOffer,
  allProducts,
  requestingGetMenu,
  editProductConfiguration,
  getSingleProduct,
  cartProducts,
  noMarginTop,
  carouselCard,
  isFromReviewPage,
  getOfferAvailableBusinesses,
  layoutStyle,
  currentPointsBalance,
}) => {
  const limitCharsToShow = isFromReviewPage ? 17 : 30;
  const [modalState, setModalState] = useState(false);
  const [notAvailableModalState, setNotAvailableModalState] = useState(false);

  let Card = GenericCard;
  let CardImage = GenericCardImage;

  if ('offer' === layoutStyle) {
    Card = OfferCardStyle;
    CardImage = OfferCardImage;
  }

  return (
    <React.Fragment>
      <Card
        isFromReviewPage={isFromReviewPage}
        onClick={
          () => setModalState(true)
          // getOfferAvailableBusinesses(
          //   {offer},
          //   setModalState,
          //   setNotAvailableModalState
          // )
        }
        noMarginTop={noMarginTop}
        carouselCard={carouselCard}
      >
        <ImageContainer>
          <CardImage src={offer.image_url}></CardImage>
          {!offer.eligible && !offer.activated && (
            <LockIcon className="far fa-lock" />
          )}
          <OfferPoints
            id="OfferCard-OfferPoints"
            offer={offer}
            currentPointsBalance={currentPointsBalance}
          />
        </ImageContainer>
        <CardText>
          <div>
            <Subhead id="OfferCard-Subhead">
              {offer.title.substring(0, limitCharsToShow) + '...'}
            </Subhead>
            <DescriptionText id="OfferCard-DescriptionText">
              {offer.subtitle.substring(0, limitCharsToShow) + '...'}
            </DescriptionText>
          </div>
        </CardText>
      </Card>
      {modalState && (
        <OfferDetailModal
          onClose={() => setModalState(false)}
          offer={offer}
          modalState={modalState}
          activateOffer={activateOffer}
          deactivateOffer={deactivateOffer}
          allProducts={allProducts}
          requestingGetMenu={requestingGetMenu}
          editProductConfiguration={editProductConfiguration}
          getSingleProduct={getSingleProduct}
          cartProducts={cartProducts}
          currentPointsBalance={currentPointsBalance}
        />
      )}
      {notAvailableModalState && (
        <NotAvailableModal
          open={notAvailableModalState}
          onClose={() => setNotAvailableModalState(!notAvailableModalState)}
          type="Offer"
        />
      )}
    </React.Fragment>
  );
};

export default OfferCard;
