import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigatedToOffersTab, getOffers } from './offersActions';
import { getProductToConfigure } from '../ProductCustomizer/productCustomizerActions';
import HeaderComponent from './OffersList/components/HeaderComponent';
import OffersList from './OffersList/components/OffersList';

const OffersContainer = ({
  offers,
  isRequestingGetOffers,
  navigatedToOffersTab,
  getProductToConfigure,
  getOffers,
}) => {
  useEffect(() => {
    getOffers();
    navigatedToOffersTab();
  }, []);

  return (
    <div>
      <HeaderComponent />
      {!isRequestingGetOffers && (
        <OffersList
          offers={offers}
          getProductToConfigure={getProductToConfigure}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  offers: state.offersReducer.offers,
  isRequestingGetOffers: state.offersReducer.isRequestingGetOffers,
});

export default connect(mapStateToProps, {
  navigatedToOffersTab,
  getProductToConfigure,
  getOffers,
})(OffersContainer);
