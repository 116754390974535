import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../loyaltyActions';
import GiveXLoyaltyCard from './GiveXLoyaltyCard';

const GiveXLoyaltyContainer = ({loyalty, requestingGetLoyalty, getLoyalty}) => {
  useEffect(() => {
    if (!loyalty && !requestingGetLoyalty) {
      getLoyalty();
    }
  }, []);

  if (loyalty) return <GiveXLoyaltyCard loyalty={loyalty} />;
  else return null;
};

const mapStateToProps = state => ({
  loyalty: state.loyaltyReducer.loyalty,
  requestingGetLoyalty: state.loyaltyReducer.requestingGetLoyalty,
});

export default connect(
  mapStateToProps,
  actions
)(GiveXLoyaltyContainer);
