import React from 'react';
import styled from 'styled-components';
import {
  Subhead,
  Headline,
  Title3,
  Title2,
} from '../../../components/Typography';
import appConfig from '../../../../app-config';

const progressBarTotalWidth = 200;

const Wrapper = styled.div`
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  max-width: 560px;
  width: 560px;
  margin: auto;

  @media (max-width: 786px) {
    width: unset;
    margin: 0 auto;
    max-width: 560px;
  }
`;

const PointsWrapper = styled.div`
  padding: 20px;
`;

const Points = styled.div`
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  color: ${props => props.theme.black};
  text-align: center;
`;

const CreditsWrapper = styled.div`
  padding: 12px;
  background-color: ${props => props.theme.loyaltyCreditBackgroundColor};
  text-align: center;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ProgressBarUnfilled = styled.div`
  margin: 20px auto;
  height: 12px;
  width: ${progressBarTotalWidth}px;
  background-color: ${props => props.theme.lightGray};
  border-radius: 20px;
  position: relative;
`;

const ProgressBarFilled = styled.div`
  height: 12px;
  background-color: ${props => props.theme.primaryColor};
  border-radius: 20px;
  position: absolute;
`;

const PointsInfoWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 30px;
  align-items: center;
`;

const GiveXLoyaltyCard = ({loyalty}) => {
  const {
    current_loyalty_balance,
    credit_balance,
    program_threshold,
  } = loyalty.loyalty_details.details.data;
  const filledProgressPixelWidth =
    (current_loyalty_balance / program_threshold) * progressBarTotalWidth;

  return (
    <div>
      <Wrapper>
        <PointsWrapper>
          <Subhead id="GiveXLoyaltyCard-Subhead" style={{marginBottom: '28px'}}>Your Rewards</Subhead>
          <PointsInfoWrapper>
            <div>
              <Headline id="GiveXLoyaltyCard-Headline" style={{textAlign: 'center', marginBottom: '12px'}}>
                Point Balance
              </Headline>
              <Points>{current_loyalty_balance} Pts</Points>
              <ProgressBarUnfilled>
                <ProgressBarFilled
                  style={{width: `${filledProgressPixelWidth}px`}}
                />
              </ProgressBarUnfilled>
              <div style={{textAlign: 'center'}}>
                {program_threshold - current_loyalty_balance} pts to earning{' '}
                {appConfig.loyaltyDollarThreshold} off!
              </div>
            </div>

            <InfoWrapper>
              <Info>
                <i
                  class="fas fa-mobile-alt"
                  style={{fontSize: '32px', textAlign: 'center'}}
                />
                <Subhead id="GiveXLoyaltyCard-Subhead">
                  Once you join the club, you will earn one point for every
                  dollar spent
                </Subhead>
              </Info>
              <Info>
                <i
                  class="fas fa-money-bill-alt"
                  style={{fontSize: '32px', textAlign: 'center'}}
                />
                <Subhead id="GiveXLoyaltyCard-SubheadInfo">{appConfig.loyaltyInfo}</Subhead>
              </Info>
            </InfoWrapper>
          </PointsInfoWrapper>
        </PointsWrapper>
        <CreditsWrapper>
          <Title3 style={{color: '#fff'}}>Credit</Title3>
          <Title2 style={{color: '#fff'}}>${credit_balance}</Title2>
        </CreditsWrapper>
      </Wrapper>
    </div>
  );
};

export default GiveXLoyaltyCard;
