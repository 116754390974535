import React, {useState} from 'react';
import {connect} from 'react-redux';
import {
  activateOffer,
  deactivateOffer,
  getSingleProduct,
  getOfferAvailableBusinesses,
} from '../offersActions';
import OfferCard from './components/OfferCard';

const OfferDetailsContainer = ({
  activateOffer,
  deactivateOffer,
  allProducts,
  requestingGetMenu,
  getProductToConfigure,
  getSingleProduct,
  cartProducts,
  getOfferAvailableBusinesses,
  index,
  offer,
  isFromReviewPage,
  layoutStyle,
  currentPointsBalance,
}) => {
  return (
    <OfferCard
      key={index}
      offer={offer}
      activateOffer={activateOffer}
      deactivateOffer={deactivateOffer}
      allProducts={allProducts}
      requestingGetMenu={requestingGetMenu}
      getProductToConfigure={getProductToConfigure}
      getSingleProduct={getSingleProduct}
      cartProducts={cartProducts}
      getOfferAvailableBusinesses={getOfferAvailableBusinesses}
      isFromReviewPage={isFromReviewPage}
      layoutStyle={layoutStyle}
      currentPointsBalance={currentPointsBalance}
    />
  );
};

const mapStateToProps = state => ({
  allProducts: state.menuReducer.products,
  requestingGetMenu: state.menuReducer.requestingGetMenu,
  cartProducts: state.cartReducer.products,
  currentPointsBalance:
    state.loyaltyReducer.loyalty.loyalty_details.details.current_points_balance,
});

export default connect(mapStateToProps, {
  activateOffer,
  deactivateOffer,
  getSingleProduct,
  getOfferAvailableBusinesses,
})(OfferDetailsContainer);
